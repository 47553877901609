import Fonts from "./fonts";
import { lighten, darken } from "@theme-ui/color";
const fonts = Fonts.getFonts();

const heading = {
  color: "text",
  fontFamily: "heading",
  lineHeight: "heading",
  fontWeight: "heading",
};

const defaultColors = {
  transparent: "transparent",
  black: "#000",
  dark: "#1a1b22",
  darkGray: "#232328",
  gold: "#d6b985",
  lightYellow: "#fbf4ea",
  duckEggBlue: "#d6dddb",
  white: "#fff",
  gray: {
    100: "#f7fafc",
    200: "#edf2f7",
    300: "#e2e8f0",
    400: "#cbd5e0",
    500: "#a0aec0",
    600: "#718096",
    700: "#4a5568",
    800: "#2d3748",
    900: "#1a202c",
  },
  red: {
    100: "#fff5f5",
    200: "#fed7d7",
    300: "#feb2b2",
    400: "#fc8181",
    500: "#f56565",
    600: "#e53e3e",
    700: "#c53030",
    800: "#9b2c2c",
    900: "#742a2a",
  },
  orange: {
    100: "#fffaf0",
    200: "#feebc8",
    300: "#fbd38d",
    400: "#f6ad55",
    500: "#ed8936",
    600: "#dd6b20",
    700: "#c05621",
    800: "#9c4221",
    900: "#7b341e",
  },
  yellow: {
    100: "#fffff0",
    200: "#fefcbf",
    300: "#faf089",
    400: "#f6e05e",
    500: "#ecc94b",
    600: "#d69e2e",
    700: "#b7791f",
    800: "#975a16",
    900: "#744210",
  },
  green: {
    100: "#f0fff4",
    200: "#c6f6d5",
    300: "#9ae6b4",
    400: "#68d391",
    500: "#48bb78",
    600: "#38a169",
    700: "#2f855a",
    800: "#276749",
    900: "#22543d",
  },
  teal: {
    100: "#e6fffa",
    200: "#b2f5ea",
    300: "#81e6d9",
    400: "#4fd1c5",
    500: "#38b2ac",
    600: "#319795",
    700: "#2c7a7b",
    800: "#285e61",
    900: "#234e52",
  },
  blue: {
    100: "#ebf8ff",
    200: "#bee3f8",
    300: "#90cdf4",
    400: "#63b3ed",
    500: "#4299e1",
    600: "#3182ce",
    700: "#2b6cb0",
    800: "#2c5282",
    900: "#2a4365",
  },
  indigo: {
    100: "#ebf4ff",
    200: "#c3dafe",
    300: "#a3bffa",
    400: "#7f9cf5",
    500: "#667eea",
    600: "#5a67d8",
    700: "#4c51bf",
    800: "#434190",
    900: "#3c366b",
  },
  purple: {
    100: "#faf5ff",
    200: "#e9d8fd",
    300: "#d6bcfa",
    400: "#b794f4",
    500: "#9f7aea",
    600: "#805ad5",
    700: "#6b46c1",
    800: "#553c9a",
    900: "#44337a",
  },
  pink: {
    100: "#fff5f7",
    200: "#fed7e2",
    300: "#fbb6ce",
    400: "#f687b3",
    500: "#ed64a6",
    600: "#d53f8c",
    700: "#b83280",
    800: "#97266d",
    900: "#702459",
  },
};

export default {
  colors: {
    ...defaultColors,
    text: defaultColors.dark,
    textInvert: defaultColors.white,
    background: defaultColors.lightYellow,
    primary: "#454d3f",
    secondary: "#f15500",
    muted: "#f6f6f9",
    highlight: "hsla(205, 100%, 40%, 0.125)",
  },
  breakpoints: ["768px", "1280px", "1380px"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: fontStack(fonts.EBGaramond.fontStack),
    heading: fontStack(fonts.Raleway.fontStack),
    monospace: "Menlo, monospace",
  },
  shadows: {
    default: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    outline: "0 0 0 3px rgba(66, 153, 225, 0.5)",
    none: "none",
  },
  fontSizes: [11, 14, 16, 20, 28, 36, 48, 56, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  root: {
    fontFamily: "body",
    lineHeight: "body",
    fontWeight: "body",
  },
  title: {
    fontFamily: "Raleway",
    color: "gold",
    fontSize: 0,
    fontWeight: "bold",
    letterSpacing: "3.3px",
  },
  slogan: {
    fontFamily: "EB Garamond",
    color: "white",
    fontSize: 6,
    fontWeight: "body",
    lineHeight: "1.17",
    mt: 3,
  },
  goto: {
    fontFamily: "Raleway",
    fontSize: 0,
    fontWeight: "bold",
    letterSpacing: "3.3px",
    cursor: "pointer",
  },
  intro: {
    fontFamily: "EB Garamond",
    color: "dark",
    fontSize: 4,
    lineHeight: "1.5",
    textAlign: "center",
    mx: "auto",
  },
  h1: {
    ...heading,
    fontSize: 0,
    color: "secondary",
  },
  h2: {
    ...heading,
    fontSize: 0,
  },
  h3: {
    ...heading,
    fontSize: 0,
  },
  h4: {
    ...heading,
    fontSize: 0,
  },
  h5: {
    ...heading,
    fontSize: 0,
  },
  h6: {
    ...heading,
    fontSize: 0,
  },
  p: {
    color: "text",
    fontFamily: "body",
    fontWeight: "body",
    lineHeight: "body",
  },
  a: {
    color: "primary",
    textDecoration: "none",
  },
  buttons: {
    primary: {
      color: "dark",
      bg: "gold",
      transition: "all 0.5sy",
      "&:hover": {
        boxShadow: "md",
        cursor: "pointer",
      },
      py: 3,
      px: 4,
      fontFamily: "Raleway",
      fontSize: 0,
      fontWeight: "bold",
      letterSpacing: "3.3px",
      borderRadius: "0",
    },
    reverse: {
      color: "dark",
      bg: "white",
      outline: "2px solid",
      outlineColor: "gold",
      transition: "all 0.5sy",
      "&:hover": {
        boxShadow: "md",
        cursor: "pointer",
      },
      py: 3,
      px: 4,
      fontFamily: "Raleway",
      fontSize: 0,
      fontWeight: "bold",
      letterSpacing: "3.3px",
      borderRadius: "0",
    },
    secondary: {
      color: "white",
      bg: "#f15500",
      transition: "all 0.5sy",
      "&:hover": {
        boxShadow: "md",
        cursor: "pointer",
      },
    },
    link: {
      color: "white",
      bg: 'transparent',
      transition: "all 0.5sy",
      fontFamily: "Raleway",
      fontSize: 2,
      fontWeight: "bold",
      borderRadius: "0",
      textDecoration: "underline",

      "&:hover": {
        textDecoration: 'none',
        cursor: 'pointer',
      }
    },
  },
  text: {
    serviceTitle: {
      letterSpacing: "3.3px",
      textAlign: "left",
    },
    serviceText: {
      fontSize: "18px",
      textAlign: "left",
      paddingTop: 2,
    },
  },
  pre: {
    fontFamily: "monospace",
    overflowX: "auto",
    code: {
      color: "inherit",
    },
  },
  code: {
    fontFamily: "monospace",
    fontSize: "inherit",
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: 0,
  },
  th: {
    textAlign: "left",
    borderBottomStyle: "solid",
  },
  td: {
    textAlign: "left",
    borderBottomStyle: "solid",
  },
  img: {
    maxWidth: "100%",
  },
  header: {
    main: {
      dark: {
        color: "dark",
        a: {
          color: "dark",
          //color: "white",
          fontSize: 0,
          fontWeight: "bold",
          display: ["block", "block", "inline-block"],
          textDecoration: "none",
          textTransform: "uppercase",
          letterSpacing: "3.3px",
          transition: "all 250ms",
          fontFamily: "Raleway",
          "&:hover": {
            color: "#666",
          },
        },
      },
      gold: {
        color: "gold",
        //color: "white",
        fontSize: 0,
        fontWeight: "bold",
        display: "inline-block",
        textDecoration: "none",
        textTransform: "uppercase",
        letterSpacing: "3.3px",
        transition: "all 250ms",
        fontFamily: "Raleway",
        "&:hover": {
          cursor: "pointer",
        },
        a: {
          color: "gold",
          //color: "white",
          fontSize: 0,
          fontWeight: "bold",
          display: ["block", "block", "inline-block"],
          textDecoration: "none",
          textTransform: "uppercase",
          letterSpacing: "3.3px",
          transition: "all 250ms",
          fontFamily: "Raleway",
          "&:hover": {
            color: "white",
          },
        },
      },
      light: {
        color: "white",
        a: {
          color: "white",
          //color: "white",
          fontSize: 0,
          fontWeight: "semi-bold",
          display: "inline-block",
          textDecoration: "none",
          textTransform: "uppercase",
          letterSpacing: "0.1em",
          transition: "all 250ms",
          "&:hover": {
            color: "#d8d8d8",
          },
        },
      },
    },
  },
  bar: {
    // ...bar,
    fontSize: 2,
    fontFamily: "Raleway",
  },
  modal: {},
  text: {
    description: {
      fontSize: 1,
    },
    cookieComponent: {
      fontSize: 0,
      fontFamily: "Raleway",
    },
  },
  variants: {
    link: {
      textDecoration: "none",
      color: "dark",
      "&:hover": {
        color: "primary",
      },
      "&:active": {
        color: "inherit",
      },
      "&:visited": {
        color: "inherit",
      },
      none: {
        textDecoration: "none",
        color: "inherit",
        "&:hover": {
          color: "inherit",
        },
        "&:active": {
          color: "inherit",
        },
        "&:visited": {
          color: "inherit",
        },
      },
      white: {
        textDecoration: "none",
        color: "white",
        "&:hover": {
          color: darken("white", 0.15),
        },
      },
      primary: {
        textDecoration: "none",
        color: defaultColors.primary,
        "&:hover": {
          color: darken("primary", 0.15),
        },
      },
    },
    headingContent: {
      fontSize: 4,
      paddingTop: 4,
    },
    subMenu: {
      bg: "dark",
      //mt: 3,
      p: 3,
      boxShadow: "md",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "gold",
      a: {
        pt: [3, 3, 3],
        color: "gold",
        "&:hover": {
          color: "white",
        },
        "&:last-child": {
          pb: [3, 3, 3],
        },
      },
    },
    contactBox: {
      fontSize: 3,
      lineHeight: "56px",
      textAlign: "center",
      h3: {
        fontFamily: "Raleway",
        fontWeight: "bold",
        fontSize: 0,
        color: "white",
        mb: 2,
        letterSpacing: "3.3px",
      },
    },
    contactForm: {
      pt: [3, 5],
      h3: {
        fontFamily: "Raleway",
        fontWeight: "bold",
        fontSize: 0,
        color: "gold",
        mb: 2,
        letterSpacing: "3.3px",
      },
      label: {
        fontFamily: "Raleway",
        fontWeight: "bold",
        fontSize: 0,
        color: "gold",
        mb: 2,
        letterSpacing: "3.3px",
      },
      button: {
        bg: "gold",
        p: 3,
        fontFamily: "Raleway",
        fontWeight: "bold",
        letterSpacing: "3.3px",
        border: 0,
        borderRadius: 0,
        fontSize: 1,
      },
    },
  },
};

function fontStack(fonts) {
  return fonts
    .map((font) => (font.includes(" ") ? `"${font}"` : font))
    .join(", ");
}
