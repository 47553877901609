//fontsLoader by iSmart (https://www.ismart.be)
//these fonts will ALL be added to the theme system and preloaded in browser. Please be carefull with adding to many because it adds latency to your pageloads. #iSmart

/* font object 
{
    name: {
        url: "https://fonturl"
        fontStack: ['Font Type', 'serif']   // use same syntax as for instance google fonts 
    },
}
*/

const defaultFonts = {
  Raleway: {
    url: "https://fonts.googleapis.com/css?family=Raleway&display=swap",
    fontStack: ["Raleway", "sans-serif"]
  },
  EBGaramond: {
    url: "https://fonts.googleapis.com/css?family=EB+Garamond&display=swap",
    fontStack: ["EB Garamond", "serif"]
  }
};

class Fonts {
  constructor(data = {}) {
    this.fonts = data;
  }

  fontLoader() {
    const fonts = this.fonts;

    let fontLoaderArray = [];
    if (typeof fonts === "object") {
      Object.keys(fonts).map(function(key, index) {
        let font = fonts[key];
        if (font.url) {
          fontLoaderArray.push(
            <link key={key} href={font.url} rel="stylesheet" />
          );
        }
      });
    }
    return fontLoaderArray;
  }

  getFonts() {
    return this.fonts;
  }
}

const fontInstance = new Fonts(defaultFonts);

// Usage:
export default fontInstance;
