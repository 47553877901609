import {
  useEffect,
  useState,
  createContext,
  useCallback,
  useMemo,
} from 'react';
import { useCookies } from 'react-cookie';

const initialConsents = {
  stateLoaded: false,
  consentGiven: false,
  consentUpdated: false,
  showConsentForm: false,
  adConsentGranted: 'denied',
  analyticsConsentGranted: 'denied',
  functionalityConsentGranted: 'granted',
  personalizationConsentGranted: 'granted',
  securityConsentGranted: 'granted',
};

const ConsentContext = createContext({
  ...initialConsents,
  toggleModal: () => {
    return true;
  },
  closeModal: () => {
    return true;
  },
  addConsent: () => {
    return true;
  },
});

const ConsentProvider = ({ children, cookieName = 'ismart_cookie_consent_data' }) => {
  const [consentState, setConsentState] = useState(initialConsents);
  const [cookies, setCookie] = useCookies([cookieName]);

  useEffect(() => {
    const storedConsent = cookies[cookieName];

    if (storedConsent) {
      try {
        const parsedConsent = storedConsent;
        setConsentState(prevState => ({
          ...prevState,
          adConsentGranted:
            parsedConsent.adConsentGranted === 'granted' ? 'granted' : 'denied',
          analyticsConsentGranted:
            parsedConsent.analyticsConsentGranted === 'granted'
              ? 'granted'
              : 'denied',
          functionalityConsentGranted:
            parsedConsent.functionalityConsentGranted === 'granted'
              ? 'granted'
              : 'denied',
          personalizationConsentGranted:
            parsedConsent.personalizationConsentGranted === 'granted'
              ? 'granted'
              : 'denied',
          securityConsentGranted:
            parsedConsent.securityConsentGranted === 'granted'
              ? 'granted'
              : 'denied',
          stateLoaded: true,
          consentGiven: true,
          consentUpdated: false,
        }));
      } catch (err) {
        setConsentState(prevState => ({
          ...prevState,
          stateLoaded: true,
          consentUpdated: false,
        }));
      }
    } else {
      setConsentState(prevState => ({
        ...prevState,
        stateLoaded: true,
        consentUpdated: false,
      }));
    }
  }, [cookies, cookieName]);

  useEffect(() => {
    // only save when consent is updated to avoid unnecessary local storage interactions
    if (consentState.consentUpdated) {
      setCookie(
        cookieName,
        {
          adConsentGranted:
            consentState.adConsentGranted === 'granted' ? 'granted' : 'denied',
          analyticsConsentGranted:
            consentState.analyticsConsentGranted === 'granted'
              ? 'granted'
              : 'denied',
          functionalityConsentGranted:
            consentState.functionalityConsentGranted === 'granted'
              ? 'granted'
              : 'denied',
          personalizationConsentGranted:
            consentState.personalizationConsentGranted === 'granted'
              ? 'granted'
              : 'denied',
          securityConsentGranted:
            consentState.securityConsentGranted === 'granted'
              ? 'granted'
              : 'denied',
        },
        {
          maxAge: 2628288,
        },
      );

      setConsentState(prevState => ({
        ...prevState,
        consentUpdated: false,
      }));
    }
  }, [consentState, cookieName, setCookie]);

  const addConsent = useCallback(
    (consents) => {
      setConsentState(prevState => {
        return {
          ...prevState,
          consentUpdated: true,
          ...consents,
        };
      });
    },
    [setConsentState],
  );

  const toggleModal = useCallback(() => {
    setConsentState(prevState => {
      return {
        ...prevState,
        showConsentForm: !prevState.showConsentForm,
      };
    });
  }, [setConsentState]);

  const closeModal = useCallback(() => {
    setConsentState(prevState => {
      return {
        ...prevState,
        showConsentForm: false,
      };
    });
  }, [setConsentState]);

  const value = useMemo(
    () => ({
      ...consentState,
      addConsent,
      toggleModal,
      closeModal,
    }),
    [consentState, addConsent, closeModal, toggleModal],
  );
  return (
    <ConsentContext.Provider value={value}>{children}</ConsentContext.Provider>
  );
};

export { ConsentProvider, ConsentContext };
